/**
 * @generated SignedSource<<e6cd862ae88010c569d13d5756dc9cde>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type useHasAIJourneysAccessFragment_query$data = {
  readonly featureFlags: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
      };
    }>;
  } | null;
  readonly " $fragmentType": "useHasAIJourneysAccessFragment_query";
};
export type useHasAIJourneysAccessFragment_query$key = {
  readonly " $data"?: useHasAIJourneysAccessFragment_query$data;
  readonly " $fragmentSpreads": FragmentRefs<"useHasAIJourneysAccessFragment_query">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "companyId"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "useHasAIJourneysAccessFragment_query",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "companyId",
          "variableName": "companyId"
        },
        {
          "kind": "Literal",
          "name": "flagNames",
          "value": [
            "ENABLE_SUBSCRIPTION_OPT_IN_SPLIT_FOR_AUTO_JOURNEYS",
            "ENABLE_PRODUCT_VIEW_SPLIT_FOR_AUTO_JOURNEYS",
            "ENABLE_PAGE_VIEW_SPLIT_FOR_AUTO_JOURNEYS",
            "ENABLE_EVENT_SPLIT_FOR_AUTO_JOURNEYS",
            "ENABLE_ATC_EVENT_SPLIT_FOR_AUTO_JOURNEYS"
          ]
        }
      ],
      "concreteType": "FeatureFlagConnection",
      "kind": "LinkedField",
      "name": "featureFlags",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "FeatureFlagEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "FeatureFlag",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "id",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "231498c22b3b59661494a2732e56a78e";

export default node;
