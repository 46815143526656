/**
 * @generated SignedSource<<6b50cd67484643905c7644dae04a8690>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TestPerformanceFragment_autoJourney$data = {
  readonly " $fragmentSpreads": FragmentRefs<"TestPerformanceTableFragment_autoJourney">;
  readonly " $fragmentType": "TestPerformanceFragment_autoJourney";
};
export type TestPerformanceFragment_autoJourney$key = {
  readonly " $data"?: TestPerformanceFragment_autoJourney$data;
  readonly " $fragmentSpreads": FragmentRefs<"TestPerformanceFragment_autoJourney">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TestPerformanceFragment_autoJourney",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "TestPerformanceTableFragment_autoJourney"
    }
  ],
  "type": "AutoJourney",
  "abstractKey": null
};

(node as any).hash = "7ab8af18ffffade65b625b2b1d11faa5";

export default node;
