/**
 * @generated SignedSource<<1b0ef2b12e283cef41e23d1f1217ba11>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AutoJourneyType = "CART_ABANDONMENT" | "UNIFIED" | "UNKNOWN" | "WELCOME" | "%future added value";
export type JourneyTriggerType = "JOURNEY_TRIGGER_TYPE_ADD_TO_CART" | "JOURNEY_TRIGGER_TYPE_BACK_IN_STOCK" | "JOURNEY_TRIGGER_TYPE_CUSTOM_EVENT" | "JOURNEY_TRIGGER_TYPE_INTEGRATION_EVENT" | "JOURNEY_TRIGGER_TYPE_JOINED_A_SEGMENT" | "JOURNEY_TRIGGER_TYPE_LOW_INVENTORY" | "JOURNEY_TRIGGER_TYPE_OPT_IN_BACK_IN_STOCK" | "JOURNEY_TRIGGER_TYPE_ORDER_CREATED" | "JOURNEY_TRIGGER_TYPE_ORDER_FULFILLED" | "JOURNEY_TRIGGER_TYPE_ORDER_SUBSCRIPTION_CHARGE_FAILED" | "JOURNEY_TRIGGER_TYPE_ORDER_SUBSCRIPTION_CREATED" | "JOURNEY_TRIGGER_TYPE_ORDER_SUBSCRIPTION_SKIPPED" | "JOURNEY_TRIGGER_TYPE_ORDER_SUBSCRIPTION_UPCOMING_CHARGE" | "JOURNEY_TRIGGER_TYPE_PAGE_VIEW" | "JOURNEY_TRIGGER_TYPE_PRICE_DROP" | "JOURNEY_TRIGGER_TYPE_PRODUCT_VIEW" | "JOURNEY_TRIGGER_TYPE_PURCHASE" | "JOURNEY_TRIGGER_TYPE_SIGNED_UP" | "JOURNEY_TRIGGER_TYPE_SIGNED_UP_FOR_EMAIL" | "JOURNEY_TRIGGER_TYPE_TEXTED_A_KEYWORD" | "JOURNEY_TRIGGER_TYPE_UNKNOWN" | "JOURNEY_TRIGGER_TYPE_USER_WIN_BACK" | "%future added value";
export type CreateAutoJourneyInput = {
  autoJourneyType?: AutoJourneyType | null;
  clientSettings: AutoJourneyClientSettingsInput;
  companyId: string;
  config: AutoJourneyConfigInput;
  messageSequencesCount?: number | null;
  name: string;
  type: JourneyTriggerType;
};
export type AutoJourneyClientSettingsInput = {
  utmSettingsInput: UtmSettingsInput;
};
export type UtmSettingsInput = {
  customUrlInputParams?: ReadonlyArray<CustomUrlInputParamsEntry> | null;
  isUtmTrackingEnabled: boolean;
  utmCampaign?: string | null;
};
export type CustomUrlInputParamsEntry = {
  key: string;
  value: string;
};
export type AutoJourneyConfigInput = {
  journeyId?: number | null;
  trafficPercentage?: number | null;
};
export type AIJourneyCreateMutation$variables = {
  input: CreateAutoJourneyInput;
};
export type AIJourneyCreateMutation$data = {
  readonly createAutoJourney: {
    readonly journey: {
      readonly id: string;
      readonly " $fragmentSpreads": FragmentRefs<"AIJourneysTableRowFragment_autoJourney">;
    };
  } | null;
};
export type AIJourneyCreateMutation = {
  response: AIJourneyCreateMutation$data;
  variables: AIJourneyCreateMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AIJourneyCreateMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateAutoJourneyPayload",
        "kind": "LinkedField",
        "name": "createAutoJourney",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AutoJourney",
            "kind": "LinkedField",
            "name": "journey",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "AIJourneysTableRowFragment_autoJourney"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AIJourneyCreateMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateAutoJourneyPayload",
        "kind": "LinkedField",
        "name": "createAutoJourney",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AutoJourney",
            "kind": "LinkedField",
            "name": "journey",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "status",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "AutoJourneyConfig",
                "kind": "LinkedField",
                "name": "config",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "trafficPercentage",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "updated",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "678df8246409656afee7c0e4fae75220",
    "id": null,
    "metadata": {},
    "name": "AIJourneyCreateMutation",
    "operationKind": "mutation",
    "text": "mutation AIJourneyCreateMutation(\n  $input: CreateAutoJourneyInput!\n) {\n  createAutoJourney(input: $input) {\n    journey {\n      id\n      ...AIJourneysTableRowFragment_autoJourney\n    }\n  }\n}\n\nfragment AIJourneysTableRowFragment_autoJourney on AutoJourney {\n  id\n  name\n  status\n  config {\n    trafficPercentage\n  }\n  updated\n}\n"
  }
};
})();

(node as any).hash = "828a0956ffe38adfd46d1df8e7534865";

export default node;
