/**
 * @generated SignedSource<<bddf261be5a998548e20b21bfeff9bee>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type JourneyTriggerType = "JOURNEY_TRIGGER_TYPE_ADD_TO_CART" | "JOURNEY_TRIGGER_TYPE_BACK_IN_STOCK" | "JOURNEY_TRIGGER_TYPE_CUSTOM_EVENT" | "JOURNEY_TRIGGER_TYPE_INTEGRATION_EVENT" | "JOURNEY_TRIGGER_TYPE_JOINED_A_SEGMENT" | "JOURNEY_TRIGGER_TYPE_LOW_INVENTORY" | "JOURNEY_TRIGGER_TYPE_OPT_IN_BACK_IN_STOCK" | "JOURNEY_TRIGGER_TYPE_ORDER_CREATED" | "JOURNEY_TRIGGER_TYPE_ORDER_FULFILLED" | "JOURNEY_TRIGGER_TYPE_ORDER_SUBSCRIPTION_CHARGE_FAILED" | "JOURNEY_TRIGGER_TYPE_ORDER_SUBSCRIPTION_CREATED" | "JOURNEY_TRIGGER_TYPE_ORDER_SUBSCRIPTION_SKIPPED" | "JOURNEY_TRIGGER_TYPE_ORDER_SUBSCRIPTION_UPCOMING_CHARGE" | "JOURNEY_TRIGGER_TYPE_PAGE_VIEW" | "JOURNEY_TRIGGER_TYPE_PRICE_DROP" | "JOURNEY_TRIGGER_TYPE_PRODUCT_VIEW" | "JOURNEY_TRIGGER_TYPE_PURCHASE" | "JOURNEY_TRIGGER_TYPE_SIGNED_UP" | "JOURNEY_TRIGGER_TYPE_SIGNED_UP_FOR_EMAIL" | "JOURNEY_TRIGGER_TYPE_TEXTED_A_KEYWORD" | "JOURNEY_TRIGGER_TYPE_UNKNOWN" | "JOURNEY_TRIGGER_TYPE_USER_WIN_BACK" | "%future added value";
export type AIJourneyCreateQuery$variables = {
  journeyId: string;
};
export type AIJourneyCreateQuery$data = {
  readonly journey: {
    readonly internalId?: string;
    readonly journeyTrigger?: {
      readonly type: JourneyTriggerType;
    } | null;
  };
};
export type AIJourneyCreateQuery$rawResponse = {
  readonly journey: {
    readonly __typename: "Journey";
    readonly id: string;
    readonly internalId: string;
    readonly journeyTrigger: {
      readonly id: string;
      readonly type: JourneyTriggerType;
    } | null;
  } | {
    readonly __typename: string;
    readonly id: string;
  } | null;
};
export type AIJourneyCreateQuery = {
  rawResponse: AIJourneyCreateQuery$rawResponse;
  response: AIJourneyCreateQuery$data;
  variables: AIJourneyCreateQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "journeyId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "journeyId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "internalId",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AIJourneyCreateQuery",
    "selections": [
      {
        "kind": "RequiredField",
        "field": {
          "alias": "journey",
          "args": (v1/*: any*/),
          "concreteType": null,
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            {
              "kind": "InlineFragment",
              "selections": [
                (v2/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "JourneyTrigger",
                  "kind": "LinkedField",
                  "name": "journeyTrigger",
                  "plural": false,
                  "selections": [
                    (v3/*: any*/)
                  ],
                  "storageKey": null
                }
              ],
              "type": "Journey",
              "abstractKey": null
            }
          ],
          "storageKey": null
        },
        "action": "THROW",
        "path": "journey"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AIJourneyCreateQuery",
    "selections": [
      {
        "alias": "journey",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "JourneyTrigger",
                "kind": "LinkedField",
                "name": "journeyTrigger",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v4/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "type": "Journey",
            "abstractKey": null
          },
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "d3d3d56a4a8ada76452b704f8bfa1ae2",
    "id": null,
    "metadata": {},
    "name": "AIJourneyCreateQuery",
    "operationKind": "query",
    "text": "query AIJourneyCreateQuery(\n  $journeyId: ID!\n) {\n  journey: node(id: $journeyId) {\n    __typename\n    ... on Journey {\n      internalId\n      journeyTrigger {\n        type\n        id\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "71072ea282bacce3d8fd7b79c231dde3";

export default node;
