/**
 * @generated SignedSource<<8b18f5050bb5bfaa89c4593f954d8e26>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ApprovalStatus = "APPROVAL_STATUS_APPROVAL_REQUESTED" | "APPROVAL_STATUS_APPROVED" | "APPROVAL_STATUS_NEEDS_APPROVAL" | "APPROVAL_STATUS_REJECTED" | "APPROVAL_STATUS_UNKNOWN" | "%future added value";
export type AutoJourneyStatus = "AUTO_JOURNEY_STATUS_ACTIVE" | "AUTO_JOURNEY_STATUS_DELETED" | "AUTO_JOURNEY_STATUS_PAUSED" | "AUTO_JOURNEY_STATUS_UNKNOWN" | "%future added value";
export type CurrencyCode = "AED" | "AFN" | "ALL" | "AMD" | "ANG" | "AOA" | "ARS" | "AUD" | "AWG" | "AZN" | "BAM" | "BBD" | "BDT" | "BGN" | "BHD" | "BIF" | "BMD" | "BND" | "BOB" | "BOV" | "BRL" | "BSD" | "BTN" | "BWP" | "BYN" | "BZD" | "CAD" | "CDF" | "CHE" | "CHF" | "CHW" | "CLF" | "CLP" | "CNY" | "COP" | "COU" | "CRC" | "CUC" | "CUP" | "CURRENCY_UNKNOWN" | "CVE" | "CZK" | "DJF" | "DKK" | "DOP" | "DZD" | "EGP" | "ERN" | "ETB" | "EUR" | "FJD" | "FKP" | "GBP" | "GEL" | "GHS" | "GIP" | "GMD" | "GNF" | "GTQ" | "GYD" | "HKD" | "HNL" | "HRK" | "HTG" | "HUF" | "IDR" | "ILS" | "INR" | "IQD" | "IRR" | "ISK" | "JMD" | "JOD" | "JPY" | "KES" | "KGS" | "KHR" | "KMF" | "KPW" | "KRW" | "KWD" | "KYD" | "KZT" | "LAK" | "LBP" | "LKR" | "LRD" | "LSL" | "LYD" | "MAD" | "MDL" | "MGA" | "MKD" | "MMK" | "MNT" | "MOP" | "MRU" | "MUR" | "MVR" | "MWK" | "MXN" | "MXV" | "MYR" | "MZN" | "NAD" | "NGN" | "NIO" | "NOK" | "NPR" | "NZD" | "OMR" | "PAB" | "PEN" | "PGK" | "PHP" | "PKR" | "PLN" | "PYG" | "QAR" | "RON" | "RSD" | "RUB" | "RWF" | "SAR" | "SBD" | "SCR" | "SDG" | "SEK" | "SGD" | "SHP" | "SLE" | "SLL" | "SOS" | "SRD" | "SSP" | "STN" | "SVC" | "SYP" | "SZL" | "THB" | "TJS" | "TMT" | "TND" | "TOP" | "TRY" | "TTD" | "TWD" | "TZS" | "UAH" | "UGX" | "USD" | "USN" | "UYI" | "UYU" | "UYW" | "UZS" | "VED" | "VES" | "VND" | "VUV" | "WST" | "XAF" | "XAG" | "XAU" | "XBA" | "XBB" | "XBC" | "XBD" | "XCD" | "XDR" | "XOF" | "XPD" | "XPF" | "XPT" | "XSU" | "XTS" | "XUA" | "XXX" | "YER" | "ZAR" | "ZMW" | "ZWL" | "%future added value";
export type JourneyRevisionPublishedStatus = "JOURNEY_REVISION_PUBLISHED_STATUS_ACTIVE" | "JOURNEY_REVISION_PUBLISHED_STATUS_DRAFT" | "JOURNEY_REVISION_PUBLISHED_STATUS_INACTIVE" | "JOURNEY_REVISION_PUBLISHED_STATUS_SCHEDULED" | "JOURNEY_REVISION_PUBLISHED_STATUS_UNKNOWN" | "%future added value";
export type JourneyTriggerType = "JOURNEY_TRIGGER_TYPE_ADD_TO_CART" | "JOURNEY_TRIGGER_TYPE_BACK_IN_STOCK" | "JOURNEY_TRIGGER_TYPE_CUSTOM_EVENT" | "JOURNEY_TRIGGER_TYPE_INTEGRATION_EVENT" | "JOURNEY_TRIGGER_TYPE_JOINED_A_SEGMENT" | "JOURNEY_TRIGGER_TYPE_LOW_INVENTORY" | "JOURNEY_TRIGGER_TYPE_OPT_IN_BACK_IN_STOCK" | "JOURNEY_TRIGGER_TYPE_ORDER_CREATED" | "JOURNEY_TRIGGER_TYPE_ORDER_FULFILLED" | "JOURNEY_TRIGGER_TYPE_ORDER_SUBSCRIPTION_CHARGE_FAILED" | "JOURNEY_TRIGGER_TYPE_ORDER_SUBSCRIPTION_CREATED" | "JOURNEY_TRIGGER_TYPE_ORDER_SUBSCRIPTION_SKIPPED" | "JOURNEY_TRIGGER_TYPE_ORDER_SUBSCRIPTION_UPCOMING_CHARGE" | "JOURNEY_TRIGGER_TYPE_PAGE_VIEW" | "JOURNEY_TRIGGER_TYPE_PRICE_DROP" | "JOURNEY_TRIGGER_TYPE_PRODUCT_VIEW" | "JOURNEY_TRIGGER_TYPE_PURCHASE" | "JOURNEY_TRIGGER_TYPE_SIGNED_UP" | "JOURNEY_TRIGGER_TYPE_SIGNED_UP_FOR_EMAIL" | "JOURNEY_TRIGGER_TYPE_TEXTED_A_KEYWORD" | "JOURNEY_TRIGGER_TYPE_UNKNOWN" | "JOURNEY_TRIGGER_TYPE_USER_WIN_BACK" | "%future added value";
export type SubscriptionType = "TYPE_CHECKOUT_ABANDONED" | "TYPE_MARKETING" | "TYPE_TRANSACTIONAL" | "TYPE_UNKNOWN" | "%future added value";
export type JourneysTableML_Query$variables = {
  after?: string | null;
  companyId: string;
  first: number;
  journeyTriggerId?: string | null;
  searchTerm?: string | null;
  status?: JourneyRevisionPublishedStatus | null;
};
export type JourneysTableML_Query$data = {
  readonly company: {
    readonly autoJourneys?: {
      readonly journeys: ReadonlyArray<{
        readonly config: {
          readonly journey: {
            readonly id: string;
          } | null;
          readonly trafficPercentage: number | null;
        };
        readonly id: string;
        readonly status: AutoJourneyStatus;
        readonly type: JourneyTriggerType;
      }>;
    } | null;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"JourneysTableML_Companies_fragment">;
};
export type JourneysTableML_Query$rawResponse = {
  readonly company: {
    readonly __typename: "Company";
    readonly __id?: string;
    readonly autoJourneys: {
      readonly journeys: ReadonlyArray<{
        readonly config: {
          readonly journey: {
            readonly id: string;
          } | null;
          readonly trafficPercentage: number | null;
        };
        readonly id: string;
        readonly status: AutoJourneyStatus;
        readonly type: JourneyTriggerType;
      }>;
    } | null;
    readonly id: string;
    readonly journeys: {
      readonly __id?: string;
      readonly edges: ReadonlyArray<{
        readonly cursor: string;
        readonly node: {
          readonly __typename: "Journey";
          readonly approvalStatus: ApprovalStatus;
          readonly draftRevision: {
            readonly approvalFlowRequired: boolean;
            readonly id: string;
            readonly internalId: number;
            readonly lastUpdatedTime: SerializedDateTime;
            readonly publishedStatus: JourneyRevisionPublishedStatus;
          } | null;
          readonly eligibleSubscriptionTypes: ReadonlyArray<SubscriptionType>;
          readonly id: string;
          readonly internalId: string;
          readonly isActive: boolean;
          readonly journeyTrigger: {
            readonly id: string;
            readonly type: JourneyTriggerType;
          } | null;
          readonly name: string;
          readonly publishedRevision: {
            readonly id: string;
            readonly internalId: number;
            readonly lastUpdatedTime: SerializedDateTime;
          } | null;
          readonly stats: {
            readonly attributedRevenue: {
              readonly amount: string;
              readonly currency: CurrencyCode | null;
            };
            readonly attributedRevenueByCurrency: ReadonlyArray<{
              readonly amount: string;
              readonly currency: CurrencyCode | null;
            }>;
            readonly clicks: number;
            readonly messagesSent: number;
          } | null;
        };
      }>;
      readonly pageInfo: {
        readonly endCursor: string;
        readonly hasNextPage: boolean;
      };
      readonly totalCount: number;
    } | null;
  } | {
    readonly __typename: string;
    readonly id: string;
  } | null;
};
export type JourneysTableML_Query = {
  rawResponse: JourneysTableML_Query$rawResponse;
  response: JourneysTableML_Query$data;
  variables: JourneysTableML_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "after"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "companyId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "journeyTriggerId"
},
v4 = {
  "defaultValue": "",
  "kind": "LocalArgument",
  "name": "searchTerm"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "status"
},
v6 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "companyId"
  }
],
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "statuses",
      "value": [
        "AUTO_JOURNEY_STATUS_ACTIVE"
      ]
    }
  ],
  "concreteType": "ListAutoJourneysResponse",
  "kind": "LinkedField",
  "name": "autoJourneys",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "AutoJourney",
      "kind": "LinkedField",
      "name": "journeys",
      "plural": true,
      "selections": [
        (v7/*: any*/),
        (v8/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "status",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "AutoJourneyConfig",
          "kind": "LinkedField",
          "name": "config",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "trafficPercentage",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "Journey",
              "kind": "LinkedField",
              "name": "journey",
              "plural": false,
              "selections": [
                (v7/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": "autoJourneys(statuses:[\"AUTO_JOURNEY_STATUS_ACTIVE\"])"
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v11 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "after"
  },
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "journeyTriggerId",
        "variableName": "journeyTriggerId"
      },
      {
        "kind": "Variable",
        "name": "searchTerm",
        "variableName": "searchTerm"
      },
      {
        "kind": "Variable",
        "name": "status",
        "variableName": "status"
      }
    ],
    "kind": "ObjectValue",
    "name": "filter"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  }
],
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "internalId",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastUpdatedTime",
  "storageKey": null
},
v14 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "currency",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "amount",
    "storageKey": null
  }
],
v15 = {
  "kind": "ClientExtension",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__id",
      "storageKey": null
    }
  ]
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "JourneysTableML_Query",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "JourneysTableML_Companies_fragment"
      },
      {
        "alias": "company",
        "args": (v6/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              (v9/*: any*/)
            ],
            "type": "Company",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v2/*: any*/),
      (v0/*: any*/),
      (v5/*: any*/),
      (v4/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Operation",
    "name": "JourneysTableML_Query",
    "selections": [
      {
        "alias": "company",
        "args": (v6/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v10/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": (v11/*: any*/),
                "concreteType": "JourneyConnection",
                "kind": "LinkedField",
                "name": "journeys",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "JourneyEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Journey",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v7/*: any*/),
                          (v12/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "name",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "isActive",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "approvalStatus",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "eligibleSubscriptionTypes",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "JourneyRevision",
                            "kind": "LinkedField",
                            "name": "draftRevision",
                            "plural": false,
                            "selections": [
                              (v13/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "publishedStatus",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "approvalFlowRequired",
                                "storageKey": null
                              },
                              (v12/*: any*/),
                              (v7/*: any*/)
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "JourneyRevision",
                            "kind": "LinkedField",
                            "name": "publishedRevision",
                            "plural": false,
                            "selections": [
                              (v13/*: any*/),
                              (v12/*: any*/),
                              (v7/*: any*/)
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "JourneyStats",
                            "kind": "LinkedField",
                            "name": "stats",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "messagesSent",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "clicks",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "Money",
                                "kind": "LinkedField",
                                "name": "attributedRevenue",
                                "plural": false,
                                "selections": (v14/*: any*/),
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "Money",
                                "kind": "LinkedField",
                                "name": "attributedRevenueByCurrency",
                                "plural": true,
                                "selections": (v14/*: any*/),
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "JourneyTrigger",
                            "kind": "LinkedField",
                            "name": "journeyTrigger",
                            "plural": false,
                            "selections": [
                              (v8/*: any*/),
                              (v7/*: any*/)
                            ],
                            "storageKey": null
                          },
                          (v10/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "cursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "totalCount",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endCursor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasNextPage",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v15/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v11/*: any*/),
                "filters": [
                  "filter"
                ],
                "handle": "connection",
                "key": "JourneysTableML_company_journeys",
                "kind": "LinkedHandle",
                "name": "journeys"
              },
              (v15/*: any*/),
              (v9/*: any*/)
            ],
            "type": "Company",
            "abstractKey": null
          },
          (v7/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "918dc952e8bf0b204499a4c1fc637ca3",
    "id": null,
    "metadata": {},
    "name": "JourneysTableML_Query",
    "operationKind": "query",
    "text": "query JourneysTableML_Query(\n  $companyId: ID!\n  $first: Int!\n  $after: String\n  $status: JourneyRevisionPublishedStatus = null\n  $searchTerm: String = \"\"\n  $journeyTriggerId: ID = null\n) {\n  ...JourneysTableML_Companies_fragment\n  company: node(id: $companyId) {\n    __typename\n    ... on Company {\n      autoJourneys(statuses: [AUTO_JOURNEY_STATUS_ACTIVE]) {\n        journeys {\n          id\n          type\n          status\n          config {\n            trafficPercentage\n            journey {\n              id\n            }\n          }\n        }\n      }\n    }\n    id\n  }\n}\n\nfragment JourneysTableML_Companies_fragment on Query {\n  company: node(id: $companyId) {\n    __typename\n    ... on Company {\n      journeys(first: $first, after: $after, filter: {status: $status, journeyTriggerId: $journeyTriggerId, searchTerm: $searchTerm}) {\n        edges {\n          node {\n            id\n            ...JourneysTableML_Journey_fragment\n            __typename\n          }\n          cursor\n        }\n        totalCount\n        pageInfo {\n          endCursor\n          hasNextPage\n        }\n      }\n    }\n    id\n  }\n}\n\nfragment JourneysTableML_Journey_fragment on Journey {\n  id\n  internalId\n  name\n  isActive\n  approvalStatus\n  eligibleSubscriptionTypes\n  draftRevision {\n    lastUpdatedTime\n    publishedStatus\n    approvalFlowRequired\n    internalId\n    id\n  }\n  publishedRevision {\n    lastUpdatedTime\n    internalId\n    id\n  }\n  stats {\n    messagesSent\n    clicks\n    attributedRevenue {\n      currency\n      amount\n    }\n    attributedRevenueByCurrency {\n      currency\n      amount\n    }\n  }\n  journeyTrigger {\n    type\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "6644b0ada2d3ab4bb581e54c019022fd";

export default node;
