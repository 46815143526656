import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { useLocaleConfig } from '@attentive/acore-utils';
import { Locale, CurrencyCode } from '@attentive/locale-utils';
import { ContainedLabel, Link, Text, Box, Button, Icon, IconPopover } from '@attentive/picnic';

import { formatDateFromUnixTimestamp } from '../../utils/formatDates';
import {
  calculateClickThroughRate,
  formatMoneyValue,
  formatNumberIntoString,
  formatPercentage,
} from '../../utils/formatStats';
import { AIJourneyEstimatedRevenuePopoverText } from '../AIJourneyEstimatedRevenuePopoverText';

import { JourneysTableML_Journey_fragment$data as Journey } from './__generated__/JourneysTableML_Journey_fragment.graphql';
import { JourneysTableML_Query as QueryType } from './__generated__/JourneysTableML_Query.graphql';

export type AIJourney = NonNullable<
  NonNullable<QueryType['response']['company']>['autoJourneys']
>['journeys'][number];

const EMPTY_PLACEHOLDER = '-';

type CellType =
  | 'LAST_EDITED'
  | 'REVENUE'
  | 'CLICK_THROUGH_RATE'
  | 'STATUS'
  | 'MESSAGES_SENT'
  | 'NAME';

const getJourneyLink = ({
  publishedRevision,
  internalId,
  approvalStatus,
  draftRevision,
  eligibleSubscriptionTypes,
}: Journey) => {
  if (publishedRevision) {
    return `/journeys/${internalId}/revision/${publishedRevision.internalId}/view`;
  } else if (
    eligibleSubscriptionTypes.includes('TYPE_TRANSACTIONAL') &&
    approvalStatus === 'APPROVAL_STATUS_APPROVED' &&
    draftRevision?.approvalFlowRequired
  ) {
    return `/journeys/${internalId}/revision/${draftRevision?.internalId}/pre-approved`;
  }

  return `/journeys/${internalId}/revision/${draftRevision?.internalId}/edit`;
};

const getJourneyStatus = (journey: Journey): 'ACTIVE' | 'DRAFT' | 'INACTIVE' | null => {
  if (!journey.publishedRevision) return 'DRAFT';
  if (journey.publishedRevision && journey.isActive) return 'ACTIVE';
  if (journey.publishedRevision && !journey.isActive) return 'INACTIVE';
  return null;
};

const getLastEditedDate = (journey: Journey) => {
  const editedDate =
    getJourneyStatus(journey) === 'DRAFT'
      ? journey.draftRevision?.lastUpdatedTime
      : journey.publishedRevision?.lastUpdatedTime;
  return editedDate ? formatDateFromUnixTimestamp(editedDate) : EMPTY_PLACEHOLDER;
};

const getRevenue = (
  journey: Journey,
  locale: Locale,
  fallbackCurrencyCode: CurrencyCode = CurrencyCode.US
) => {
  if (!journey.stats) {
    return EMPTY_PLACEHOLDER;
  }

  const value = formatMoneyValue({
    amount: parseInt(journey.stats.attributedRevenue.amount) || 0,
    currencyCode:
      (journey.stats.attributedRevenue.currency as CurrencyCode) || fallbackCurrencyCode,
    locale,
  });
  return value;
};

export const useFormatCell = () => {
  const { locale, currencyConfig } = useLocaleConfig();

  return ({
    journey,
    format,
    aiJourney,
  }: {
    journey: Journey | undefined;
    format: CellType;
    aiJourney?: AIJourney;
  }): React.ReactNode => {
    if (!journey) return EMPTY_PLACEHOLDER;

    switch (format) {
      case 'REVENUE':
        return (
          <Text variant="caption" as="div">
            {getRevenue(journey, locale, currencyConfig.currencyCode)}
          </Text>
        );
      case 'CLICK_THROUGH_RATE':
        return (
          <Text variant="caption">
            {journey.stats?.clicks !== undefined && journey.stats.messagesSent !== undefined
              ? formatPercentage({
                  percentage: calculateClickThroughRate({
                    clicks: journey.stats.clicks,
                    messagesSent: journey.stats.messagesSent,
                  }),
                })
              : EMPTY_PLACEHOLDER}
          </Text>
        );
      case 'MESSAGES_SENT':
        return (
          <Text variant="caption">
            {getJourneyStatus(journey) === 'DRAFT'
              ? EMPTY_PLACEHOLDER
              : formatNumberIntoString({ locale, amount: journey.stats?.messagesSent || 0 })}
          </Text>
        );
      case 'NAME':
        return (
          <Text variant="caption">
            <Link as={RouterLink} to={getJourneyLink(journey)}>
              <strong>{journey.name}</strong>
            </Link>
          </Text>
        );
      case 'LAST_EDITED':
        return <Text variant="caption">{getLastEditedDate(journey)}</Text>;
      case 'STATUS':
        switch (getJourneyStatus(journey)) {
          case 'ACTIVE':
            return typeof aiJourney?.config.trafficPercentage === 'number' ? (
              <Box>
                <ContainedLabel variant="decorative1" css={{ whiteSpace: 'nowrap' }}>
                  Active test
                </ContainedLabel>
                <Text variant="micro" css={{ mt: '$space2' }}>
                  {100 - aiJourney.config.trafficPercentage}% of traffic
                </Text>
              </Box>
            ) : (
              <ContainedLabel variant="decorative1">Active</ContainedLabel>
            );
          case 'DRAFT':
            return <ContainedLabel variant="decorative4">Draft</ContainedLabel>;
          case 'INACTIVE':
            return <ContainedLabel variant="neutral">Inactive</ContainedLabel>;
          default:
            return EMPTY_PLACEHOLDER;
        }
      default:
        return EMPTY_PLACEHOLDER;
    }
  };
};

export const AIJourneyTestSetupCTA = ({ journeyId }: { journeyId: string }) => {
  return (
    <Box
      css={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'center',
        minWidth: 'max-content',
        m: '$space5 $space0 $space1 $space0',
      }}
    >
      <Button
        variant="primary"
        size="small"
        css={{ display: 'flex', alignItems: 'center', gap: '$space2' }}
        as={RouterLink}
        to={`../ai/create/${journeyId}`}
        aria-label="Boost revenue"
      >
        <Icon name="Magic" size="extraSmall" />
        <Text variant="caption">Boost revenue</Text>
      </Button>
      <Box css={{ display: 'flex', alignItems: 'center' }}>
        <Text variant="micro">Drive 100% more revenue</Text>
        <IconPopover align="center" size="small" iconName="CircleInformation">
          <AIJourneyEstimatedRevenuePopoverText />
        </IconPopover>
      </Box>
    </Box>
  );
};
