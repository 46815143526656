/**
 * @generated SignedSource<<d3fade5abeeed4a1ce19a1a1c64e386c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type BrandVoiceSettingsFormQuery$variables = {
  companyId: string;
};
export type BrandVoiceSettingsFormQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"useHasAIJourneysAccessFragment_query">;
};
export type BrandVoiceSettingsFormQuery$rawResponse = {
  readonly featureFlags: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
      };
    }>;
  } | null;
};
export type BrandVoiceSettingsFormQuery = {
  rawResponse: BrandVoiceSettingsFormQuery$rawResponse;
  response: BrandVoiceSettingsFormQuery$data;
  variables: BrandVoiceSettingsFormQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "companyId"
  }
],
v1 = {
  "kind": "Variable",
  "name": "companyId",
  "variableName": "companyId"
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "BrandVoiceSettingsFormQuery",
    "selections": [
      {
        "args": [
          (v1/*: any*/)
        ],
        "kind": "FragmentSpread",
        "name": "useHasAIJourneysAccessFragment_query"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "BrandVoiceSettingsFormQuery",
    "selections": [
      {
        "alias": null,
        "args": [
          (v1/*: any*/),
          {
            "kind": "Literal",
            "name": "flagNames",
            "value": [
              "ENABLE_SUBSCRIPTION_OPT_IN_SPLIT_FOR_AUTO_JOURNEYS",
              "ENABLE_PRODUCT_VIEW_SPLIT_FOR_AUTO_JOURNEYS",
              "ENABLE_PAGE_VIEW_SPLIT_FOR_AUTO_JOURNEYS",
              "ENABLE_EVENT_SPLIT_FOR_AUTO_JOURNEYS",
              "ENABLE_ATC_EVENT_SPLIT_FOR_AUTO_JOURNEYS"
            ]
          }
        ],
        "concreteType": "FeatureFlagConnection",
        "kind": "LinkedField",
        "name": "featureFlags",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "FeatureFlagEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "FeatureFlag",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "ef0ddeb1510f6bc5725da69bf6bf1fc3",
    "id": null,
    "metadata": {},
    "name": "BrandVoiceSettingsFormQuery",
    "operationKind": "query",
    "text": "query BrandVoiceSettingsFormQuery(\n  $companyId: ID!\n) {\n  ...useHasAIJourneysAccessFragment_query_MWiJC\n}\n\nfragment useHasAIJourneysAccessFragment_query_MWiJC on Query {\n  featureFlags(companyId: $companyId, flagNames: [\"ENABLE_SUBSCRIPTION_OPT_IN_SPLIT_FOR_AUTO_JOURNEYS\", \"ENABLE_PRODUCT_VIEW_SPLIT_FOR_AUTO_JOURNEYS\", \"ENABLE_PAGE_VIEW_SPLIT_FOR_AUTO_JOURNEYS\", \"ENABLE_EVENT_SPLIT_FOR_AUTO_JOURNEYS\", \"ENABLE_ATC_EVENT_SPLIT_FOR_AUTO_JOURNEYS\"]) {\n    edges {\n      node {\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "32a14e36e403bf593dc87520193ba688";

export default node;
