/**
 * @generated SignedSource<<fe6897ef04ee191185c57ff4ce8ef4e9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AttentiveAiTermsDialogQuery$variables = {
  companyId: string;
};
export type AttentiveAiTermsDialogQuery$data = {
  readonly company: {
    readonly " $fragmentSpreads": FragmentRefs<"AttentiveAiTermsDialogFragment_company">;
  };
};
export type AttentiveAiTermsDialogQuery$rawResponse = {
  readonly company: {
    readonly __typename: "Company";
    readonly generativeAiOptInStatus: {
      readonly optedIn: boolean;
    } | null;
    readonly id: string;
  } | {
    readonly __typename: string;
    readonly id: string;
  } | null;
};
export type AttentiveAiTermsDialogQuery = {
  rawResponse: AttentiveAiTermsDialogQuery$rawResponse;
  response: AttentiveAiTermsDialogQuery$data;
  variables: AttentiveAiTermsDialogQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "companyId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "companyId"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AttentiveAiTermsDialogQuery",
    "selections": [
      {
        "kind": "RequiredField",
        "field": {
          "alias": "company",
          "args": (v1/*: any*/),
          "concreteType": null,
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "AttentiveAiTermsDialogFragment_company"
            }
          ],
          "storageKey": null
        },
        "action": "THROW",
        "path": "company"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AttentiveAiTermsDialogQuery",
    "selections": [
      {
        "alias": "company",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "GetGenerativeAIOptInStatusResponse",
                "kind": "LinkedField",
                "name": "generativeAiOptInStatus",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "optedIn",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "Company",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "7300e3a1c81e5ac7ec37a68ce69fc8ef",
    "id": null,
    "metadata": {},
    "name": "AttentiveAiTermsDialogQuery",
    "operationKind": "query",
    "text": "query AttentiveAiTermsDialogQuery(\n  $companyId: ID!\n) {\n  company: node(id: $companyId) {\n    __typename\n    ...AttentiveAiTermsDialogFragment_company\n    id\n  }\n}\n\nfragment AttentiveAiTermsDialogFragment_company on Company {\n  id\n  generativeAiOptInStatus {\n    optedIn\n  }\n}\n"
  }
};
})();

(node as any).hash = "9149017a5a84ebcd03bcc4c60bf5e17f";

export default node;
