/**
 * @generated SignedSource<<cecf793a30cef86c6d1a3dd352248a31>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type AutoJourneyStatus = "AUTO_JOURNEY_STATUS_ACTIVE" | "AUTO_JOURNEY_STATUS_DELETED" | "AUTO_JOURNEY_STATUS_PAUSED" | "AUTO_JOURNEY_STATUS_UNKNOWN" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type AIJourneysTableFragment_listAutoJourneysResponse$data = {
  readonly journeys: ReadonlyArray<{
    readonly id: string;
    readonly status: AutoJourneyStatus;
    readonly " $fragmentSpreads": FragmentRefs<"AIJourneysTableRowFragment_autoJourney">;
  }>;
  readonly " $fragmentType": "AIJourneysTableFragment_listAutoJourneysResponse";
};
export type AIJourneysTableFragment_listAutoJourneysResponse$key = {
  readonly " $data"?: AIJourneysTableFragment_listAutoJourneysResponse$data;
  readonly " $fragmentSpreads": FragmentRefs<"AIJourneysTableFragment_listAutoJourneysResponse">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AIJourneysTableFragment_listAutoJourneysResponse",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "AutoJourney",
      "kind": "LinkedField",
      "name": "journeys",
      "plural": true,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "AIJourneysTableRowFragment_autoJourney"
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "status",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ListAutoJourneysResponse",
  "abstractKey": null
};

(node as any).hash = "0dd806535bf198eae7ddddaa5289befd";

export default node;
