/**
 * @generated SignedSource<<2f10b546eaba4ff65c268daba6d5f8ac>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AttentiveAiTermsDialogFragment_company$data = {
  readonly generativeAiOptInStatus: {
    readonly optedIn: boolean;
  } | null;
  readonly id: string;
  readonly " $fragmentType": "AttentiveAiTermsDialogFragment_company";
};
export type AttentiveAiTermsDialogFragment_company$key = {
  readonly " $data"?: AttentiveAiTermsDialogFragment_company$data;
  readonly " $fragmentSpreads": FragmentRefs<"AttentiveAiTermsDialogFragment_company">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AttentiveAiTermsDialogFragment_company",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "GetGenerativeAIOptInStatusResponse",
      "kind": "LinkedField",
      "name": "generativeAiOptInStatus",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "optedIn",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Company",
  "abstractKey": null
};

(node as any).hash = "dcdf0fc1e30c19f1194474f4204bf0cf";

export default node;
