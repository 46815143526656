/**
 * @generated SignedSource<<e866ece656209dc6b9183eb7be8d5703>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type GenerativeAITextFeedbackCategory = "GENERATIVE_AI_TEXT_FEEDBACK_CATEGORY_BAD_GRAMMAR" | "GENERATIVE_AI_TEXT_FEEDBACK_CATEGORY_BRAND_VOICE_MISMATCH" | "GENERATIVE_AI_TEXT_FEEDBACK_CATEGORY_CONTENT_PROMPT_MISMATCH" | "GENERATIVE_AI_TEXT_FEEDBACK_CATEGORY_INCLUDES_PII" | "GENERATIVE_AI_TEXT_FEEDBACK_CATEGORY_OFFENSIVE_LANGUAGE" | "GENERATIVE_AI_TEXT_FEEDBACK_CATEGORY_OTHER" | "GENERATIVE_AI_TEXT_FEEDBACK_CATEGORY_UNKNOWN" | "%future added value";
export type GenerativeAITextFeedbackType = "GENERATIVE_AI_TEXT_FEEDBACK_TYPE_A_B_WINNER" | "GENERATIVE_AI_TEXT_FEEDBACK_TYPE_FLAGGED" | "GENERATIVE_AI_TEXT_FEEDBACK_TYPE_GENERATE_MORE_SIMILAR" | "GENERATIVE_AI_TEXT_FEEDBACK_TYPE_RANKED_WINNER" | "GENERATIVE_AI_TEXT_FEEDBACK_TYPE_UNKNOWN" | "%future added value";
export type OpenAIModel = "OPENAI_MODEL_GPT_3_5_TURBO" | "OPENAI_MODEL_GPT_4" | "OPENAI_MODEL_GPT_4_300_TEST" | "OPENAI_MODEL_GPT_4_O" | "OPENAI_MODEL_GPT_4_TURBO" | "OPENAI_MODEL_UNKNOWN" | "OPEN_AI_MODEL_GPT_4_0_MINI" | "%future added value";
export type SaveGeneratedTextAndFeedbackInput = {
  companyId: string;
  feedbackCategory?: GenerativeAITextFeedbackCategory | null;
  feedbackType: GenerativeAITextFeedbackType;
  generatedText: string;
  generatedTextInputMetadata: GeneratedTextInputMetadata;
  groupId: string;
  messageId?: string | null;
  source: string;
  userId: string;
};
export type GeneratedTextInputMetadata = {
  maxTokens?: number | null;
  n?: number | null;
  origin?: string | null;
  temperature?: number | null;
  template?: GenerativeAITextTemplateInput | null;
  topP?: number | null;
  vendorOptions?: TextGenVendorOptions | null;
};
export type GenerativeAITextTemplateInput = {
  templateName: string;
  version: string;
};
export type TextGenVendorOptions = {
  vendorOptions?: TextGenVendorOptionsVendorOptions | null;
};
export type TextGenVendorOptionsVendorOptions = {
  openaiOptions?: OpenAIOptions | null;
};
export type OpenAIOptions = {
  model: OpenAIModel;
};
export type GeneratedTextFeedbackFormSaveGeneratedTextAndFeedbackMutation$variables = {
  input: SaveGeneratedTextAndFeedbackInput;
};
export type GeneratedTextFeedbackFormSaveGeneratedTextAndFeedbackMutation$data = {
  readonly saveGeneratedTextAndFeedback: {
    readonly statusText: string;
  } | null;
};
export type GeneratedTextFeedbackFormSaveGeneratedTextAndFeedbackMutation$rawResponse = {
  readonly saveGeneratedTextAndFeedback: {
    readonly statusText: string;
  } | null;
};
export type GeneratedTextFeedbackFormSaveGeneratedTextAndFeedbackMutation = {
  rawResponse: GeneratedTextFeedbackFormSaveGeneratedTextAndFeedbackMutation$rawResponse;
  response: GeneratedTextFeedbackFormSaveGeneratedTextAndFeedbackMutation$data;
  variables: GeneratedTextFeedbackFormSaveGeneratedTextAndFeedbackMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "SaveGeneratedTextAndFeedbackPayload",
    "kind": "LinkedField",
    "name": "saveGeneratedTextAndFeedback",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "statusText",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "GeneratedTextFeedbackFormSaveGeneratedTextAndFeedbackMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "GeneratedTextFeedbackFormSaveGeneratedTextAndFeedbackMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "dcbdfaf1fa8ee0d255a9f5ac80a8628a",
    "id": null,
    "metadata": {},
    "name": "GeneratedTextFeedbackFormSaveGeneratedTextAndFeedbackMutation",
    "operationKind": "mutation",
    "text": "mutation GeneratedTextFeedbackFormSaveGeneratedTextAndFeedbackMutation(\n  $input: SaveGeneratedTextAndFeedbackInput!\n) {\n  saveGeneratedTextAndFeedback(input: $input) {\n    statusText\n  }\n}\n"
  }
};
})();

(node as any).hash = "8cf99ffc7d5b5e0d0eff30cead45711c";

export default node;
