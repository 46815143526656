/**
 * @generated SignedSource<<7c27ad6c99d278119d2d70736de02892>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type JourneysTableML_Companies_fragment$data = {
  readonly company: {
    readonly __id?: string;
    readonly journeys?: {
      readonly __id: string;
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly id: string;
          readonly " $fragmentSpreads": FragmentRefs<"JourneysTableML_Journey_fragment">;
        };
      }>;
      readonly totalCount: number;
    } | null;
  } | null;
  readonly " $fragmentType": "JourneysTableML_Companies_fragment";
};
export type JourneysTableML_Companies_fragment$key = {
  readonly " $data"?: JourneysTableML_Companies_fragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"JourneysTableML_Companies_fragment">;
};

import JourneysTableML_Companies_refetchable_graphql from './JourneysTableML_Companies_refetchable.graphql';

const node: ReaderFragment = (function(){
var v0 = [
  "company",
  "journeys"
],
v1 = {
  "kind": "ClientExtension",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__id",
      "storageKey": null
    }
  ]
};
return {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "after"
    },
    {
      "kind": "RootArgument",
      "name": "companyId"
    },
    {
      "kind": "RootArgument",
      "name": "first"
    },
    {
      "kind": "RootArgument",
      "name": "journeyTriggerId"
    },
    {
      "kind": "RootArgument",
      "name": "searchTerm"
    },
    {
      "kind": "RootArgument",
      "name": "status"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "first",
        "cursor": "after",
        "direction": "forward",
        "path": (v0/*: any*/)
      }
    ],
    "refetch": {
      "connection": {
        "forward": {
          "count": "first",
          "cursor": "after"
        },
        "backward": null,
        "path": (v0/*: any*/)
      },
      "fragmentPathInResult": [],
      "operation": JourneysTableML_Companies_refetchable_graphql
    }
  },
  "name": "JourneysTableML_Companies_fragment",
  "selections": [
    {
      "alias": "company",
      "args": [
        {
          "kind": "Variable",
          "name": "id",
          "variableName": "companyId"
        }
      ],
      "concreteType": null,
      "kind": "LinkedField",
      "name": "node",
      "plural": false,
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": "journeys",
              "args": [
                {
                  "fields": [
                    {
                      "kind": "Variable",
                      "name": "journeyTriggerId",
                      "variableName": "journeyTriggerId"
                    },
                    {
                      "kind": "Variable",
                      "name": "searchTerm",
                      "variableName": "searchTerm"
                    },
                    {
                      "kind": "Variable",
                      "name": "status",
                      "variableName": "status"
                    }
                  ],
                  "kind": "ObjectValue",
                  "name": "filter"
                }
              ],
              "concreteType": "JourneyConnection",
              "kind": "LinkedField",
              "name": "__JourneysTableML_company_journeys_connection",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "JourneyEdge",
                  "kind": "LinkedField",
                  "name": "edges",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "Journey",
                      "kind": "LinkedField",
                      "name": "node",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "id",
                          "storageKey": null
                        },
                        {
                          "args": null,
                          "kind": "FragmentSpread",
                          "name": "JourneysTableML_Journey_fragment"
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "__typename",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "cursor",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "totalCount",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "PageInfo",
                  "kind": "LinkedField",
                  "name": "pageInfo",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "endCursor",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "hasNextPage",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                (v1/*: any*/)
              ],
              "storageKey": null
            },
            (v1/*: any*/)
          ],
          "type": "Company",
          "abstractKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();

(node as any).hash = "fa0ed345ccb2ee13a79ba5cb6cd548da";

export default node;
