/**
 * @generated SignedSource<<05c445dee02e5be98dc65a56aeae8896>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type AIImageGenerationType = "AI_IMAGE_GENERATION_TYPE_SCENE_CONSTRUCTION" | "AI_IMAGE_GENERATION_TYPE_UNKNOWN" | "%future added value";
export type GenerateAIImageInput = {
  sceneConstructionInput?: SceneConstructionInput | null;
  transformationType: AIImageGenerationType;
};
export type SceneConstructionInput = {
  imageScale?: number | null;
  inputImageUrls?: ReadonlyArray<UrlInput> | null;
  modifiers?: ReadonlyArray<SceneConstructionModifierInput> | null;
  scene: string;
  scenePreposition: string;
};
export type UrlInput = {
  value: string;
};
export type SceneConstructionModifierInput = {
  selectedValues?: ReadonlyArray<string> | null;
  title: string;
};
export type CustomizeImageDialogMutation$variables = {
  input: GenerateAIImageInput;
};
export type CustomizeImageDialogMutation$data = {
  readonly generateAIImage: {
    readonly transformedImageUrls: ReadonlyArray<{
      readonly value: string;
    }>;
  } | null;
};
export type CustomizeImageDialogMutation = {
  response: CustomizeImageDialogMutation$data;
  variables: CustomizeImageDialogMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "GenerateAIImagePayload",
    "kind": "LinkedField",
    "name": "generateAIImage",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Url",
        "kind": "LinkedField",
        "name": "transformedImageUrls",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "value",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CustomizeImageDialogMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CustomizeImageDialogMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "e0d3e2a0cadf85a837bd1f7a40b9a4db",
    "id": null,
    "metadata": {},
    "name": "CustomizeImageDialogMutation",
    "operationKind": "mutation",
    "text": "mutation CustomizeImageDialogMutation(\n  $input: GenerateAIImageInput!\n) {\n  generateAIImage(input: $input) {\n    transformedImageUrls {\n      value\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "a6260f6c5431e8a34b7c6455fbc98b90";

export default node;
