/**
 * @generated SignedSource<<7dc7622edd32b3c64feaca733526720e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type AIBrandVoiceAllCapsUsage = "ALL_CAPS_USAGE_ADAPTIVE" | "ALL_CAPS_USAGE_FREQUENTLY" | "ALL_CAPS_USAGE_NEVER" | "ALL_CAPS_USAGE_SPARINGLY" | "%future added value";
export type AIBrandVoiceEmojiFrequency = "EMOJI_FREQUENCY_ADAPTIVE" | "EMOJI_FREQUENCY_FREQUENTLY" | "EMOJI_FREQUENCY_NEVER" | "EMOJI_FREQUENCY_SPARINGLY" | "%future added value";
export type AIBrandVoiceProductTitleFormatting = "PRODUCT_TITLE_FORMATTING_LOWER_CASE" | "PRODUCT_TITLE_FORMATTING_TITLE_CASE" | "PRODUCT_TITLE_FORMATTING_UPPER_CASE" | "%future added value";
export type AIBrandVoiceUrgency = "URGENCY_ADAPTIVE" | "URGENCY_FREQUENTLY" | "URGENCY_NEVER" | "URGENCY_SPARINGLY" | "%future added value";
export type OpenAIModel = "OPENAI_MODEL_GPT_3_5_TURBO" | "OPENAI_MODEL_GPT_4" | "OPENAI_MODEL_GPT_4_300_TEST" | "OPENAI_MODEL_GPT_4_O" | "OPENAI_MODEL_GPT_4_TURBO" | "OPENAI_MODEL_UNKNOWN" | "OPEN_AI_MODEL_GPT_4_0_MINI" | "%future added value";
export type StandardErrorStatus = "STANDARD_ERROR_STATUS_ABORTED" | "STANDARD_ERROR_STATUS_ALREADY_EXISTS" | "STANDARD_ERROR_STATUS_CANCELLED" | "STANDARD_ERROR_STATUS_DATA_LOSS" | "STANDARD_ERROR_STATUS_DEADLINE_EXCEEDED" | "STANDARD_ERROR_STATUS_FAILED_PRECONDITION" | "STANDARD_ERROR_STATUS_INTERNAL" | "STANDARD_ERROR_STATUS_INVALID_ARGUMENT" | "STANDARD_ERROR_STATUS_NOT_FOUND" | "STANDARD_ERROR_STATUS_NOT_SET" | "STANDARD_ERROR_STATUS_OUT_OF_RANGE" | "STANDARD_ERROR_STATUS_PERMISSION_DENIED" | "STANDARD_ERROR_STATUS_RESOURCE_EXHAUSTED" | "STANDARD_ERROR_STATUS_UNAUTHENTICATED" | "STANDARD_ERROR_STATUS_UNAVAILABLE" | "STANDARD_ERROR_STATUS_UNIMPLEMENTED" | "STANDARD_ERROR_STATUS_UNKNOWN" | "%future added value";
export type TemplateRole = "TEMPLATE_ROLE_SYSTEM" | "TEMPLATE_ROLE_UNKNOWN" | "TEMPLATE_ROLE_USER" | "%future added value";
export type GenerateAIJourneyTextInput = {
  brandVoiceSettings?: AIBrandVoiceSettingsInputType | null;
  companyId: string;
  inputs?: ReadonlyArray<ChatMessageAdminToolInput> | null;
  metadata: GenerateAIJourneyTextMetadata;
  useMessageEvaluation?: boolean | null;
};
export type AIBrandVoiceSettingsInputType = {
  allCapsCount?: number | null;
  allCapsUsage?: AIBrandVoiceAllCapsUsage | null;
  characterCount?: number | null;
  emojiCount?: number | null;
  emojiFrequency?: AIBrandVoiceEmojiFrequency | null;
  productTitleFormatting?: AIBrandVoiceProductTitleFormatting | null;
  toneKeywords?: ReadonlyArray<string> | null;
  urgency?: AIBrandVoiceUrgency | null;
  wordCount?: number | null;
};
export type ChatMessageAdminToolInput = {
  content: string;
  role: TemplateRole;
};
export type GenerateAIJourneyTextMetadata = {
  n?: number | null;
  origin?: string | null;
  templateInput: GenerativeAITextTemplateInput;
  vendorOptions?: TextGenVendorOptions | null;
};
export type GenerativeAITextTemplateInput = {
  templateName: string;
  version: string;
};
export type TextGenVendorOptions = {
  vendorOptions?: TextGenVendorOptionsVendorOptions | null;
};
export type TextGenVendorOptionsVendorOptions = {
  openaiOptions?: OpenAIOptions | null;
};
export type OpenAIOptions = {
  model: OpenAIModel;
};
export type BrandVoicePreviewGenerateAIJourneyTextMutation$variables = {
  input: GenerateAIJourneyTextInput;
};
export type BrandVoicePreviewGenerateAIJourneyTextMutation$data = {
  readonly generateAIJourneyText: {
    readonly __typename: "DefaultErrorFailure";
    readonly message: string;
    readonly status: StandardErrorStatus;
  } | {
    readonly __typename: "GenerateAIJourneyTextSuccess";
    readonly generatedTextResponses: ReadonlyArray<{
      readonly id: string;
      readonly text: string;
    }>;
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  } | null;
};
export type BrandVoicePreviewGenerateAIJourneyTextMutation$rawResponse = {
  readonly generateAIJourneyText: {
    readonly __typename: "DefaultErrorFailure";
    readonly message: string;
    readonly status: StandardErrorStatus;
  } | {
    readonly __typename: "GenerateAIJourneyTextSuccess";
    readonly generatedTextResponses: ReadonlyArray<{
      readonly id: string;
      readonly text: string;
    }>;
  } | {
    readonly __typename: string;
  } | null;
};
export type BrandVoicePreviewGenerateAIJourneyTextMutation = {
  rawResponse: BrandVoicePreviewGenerateAIJourneyTextMutation$rawResponse;
  response: BrandVoicePreviewGenerateAIJourneyTextMutation$data;
  variables: BrandVoicePreviewGenerateAIJourneyTextMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": null,
    "kind": "LinkedField",
    "name": "generateAIJourneyText",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "__typename",
        "storageKey": null
      },
      {
        "kind": "InlineFragment",
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AIGeneratedText",
            "kind": "LinkedField",
            "name": "generatedTextResponses",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "text",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "type": "GenerateAIJourneyTextSuccess",
        "abstractKey": null
      },
      {
        "kind": "InlineFragment",
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "status",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "message",
            "storageKey": null
          }
        ],
        "type": "DefaultErrorFailure",
        "abstractKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "BrandVoicePreviewGenerateAIJourneyTextMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "BrandVoicePreviewGenerateAIJourneyTextMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "63bdf797924ada8e59b37e16e5609840",
    "id": null,
    "metadata": {},
    "name": "BrandVoicePreviewGenerateAIJourneyTextMutation",
    "operationKind": "mutation",
    "text": "mutation BrandVoicePreviewGenerateAIJourneyTextMutation(\n  $input: GenerateAIJourneyTextInput!\n) {\n  generateAIJourneyText(input: $input) {\n    __typename\n    ... on GenerateAIJourneyTextSuccess {\n      generatedTextResponses {\n        id\n        text\n      }\n    }\n    ... on DefaultErrorFailure {\n      status\n      message\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "591930e9aae2005baf8f9c086f725f53";

export default node;
